import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    config: state => state.config.value,
    reports: state => state.reports.value,
    paramsUrl: state => state.paramsUrl.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setConfig(state, payload) {
      state.config.value = payload
    },
    setReports(state, payload) {
      state.reports.value = payload
    },
    setParamsUrl(state, payload) {
      state.paramsUrl.value = payload
    },
  },
  actions: {
    // Carrega os reports do power bi
    async getReports({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'powerbi/reportsBI'
        const { idReport } = payload

        if ((idReport !== undefined) && (idReport !== '')) {
          urlRequestData = `powerbi/reportsBI/${idReport}`
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if ((idReport !== undefined) && (idReport !== '')) {
                if (response.data !== undefined) {
                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar o report do Power BI')))
                }
              } else if (response.data.data !== undefined) {
                commit('setConfig', response.data.data.powerbi)
                commit('setReports', response.data.data.blocksReports)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os reports do Power BI')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os reports do Power BI')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os reports do Power BI')))
            }
          })
      })
    },

    setParamsUrl({ commit }, payload) {
      commit('setParamsUrl', payload)
    },
  },
}
