<template>
  <div style="height: inherit">
    <div class="email-app-list position-relative">

      <b-row>
        <b-col
          md="12"
          class="title-report"
        >
          <div class="email-header-left d-flex align-items-center m-2">
            <span class="go-back mr-1">
              <feather-icon
                :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                size="20"
                class="align-bottom cursor-pointer"
                @click.stop.prevent="$router.push({ name: 'growth-powerbi' })"
              />
            </span>
            <h4 class="email-subject mb-0">
              {{ titleReport }}
            </h4>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="12"
        >
          <div id="container" />
        </b-col>
      </b-row>

    </div>
  </div>

</template>

<script>
import store from '@/store'
import {
  VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import powerbiModule from '@store-modules/growth/powerbi/index'
import { mapGetters } from 'vuex'
import { useRouter } from '@core/utils/utils'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import { useWindowSize, useCssVar } from '@vueuse/core'

export default {
  components: {
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      prevRoute: null,
      titleReport: '',
    }
  },
  computed: {
    ...mapGetters('powerbi', ['config', 'reports', 'paramsUrl']),
    ...mapGetters('auth', ['currentUser']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  watch: {
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }

    if (useCapacitor.isNative() === true) {
      useCapacitor.lockScreen()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm
      self.prevRoute = from
    })
  },

  mounted() {
    if (useCapacitor.isNative() === true) {
      useCapacitor.unlockScreen()
    }
  },
  async created() {
    try {
      this.$root.$emit('app::loading', true)

      const { typeReport } = this.paramsUrl

      const oPbi = async () => Promise.all([
        import(/* webpackChunkName: "chunk-powerbi-client" */ 'powerbi-client'),
      ]).then(([res]) => res)

      const pbi = await oPbi()

      await store.dispatch('powerbi/getReports', { idReport: '' }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.titleReport = this.reports[typeReport].txt

      await store.dispatch('powerbi/getReports', { idReport: typeReport || '' }).then(res => {
        if (res) {
          const permissions = pbi.models.Permissions.All

          let urlEmbed = this.config.reports[typeReport].embedUrl

          if (typeof this.reports[typeReport].filtro !== 'undefined' && this.reports[typeReport].filtro !== '') {
            urlEmbed += `&filter=${this.reports[typeReport].filtro}`
            urlEmbed = urlEmbed.replace('{{ID_USER}}', atob(this.currentUser.id))
            urlEmbed = urlEmbed.replace('{{ID_HUB}}', this.currentUser.idHUB)
            urlEmbed = urlEmbed.replace('{{ID_MORADA}}', this.currentUser.idMoradaHUB.join(','))

            if (typeof this.currentUser.extras !== 'undefined' && typeof this.currentUser.extras.idLeader !== 'undefined' && this.currentUser.extras.idLeader !== '') {
              urlEmbed = urlEmbed.replace('{{ID_LEADER}}', this.currentUser.extras.idLeader)
            } else {
              urlEmbed = urlEmbed.replace('{{ID_LEADER}}', 0)
            }
          }

          const config = {
            type: 'report',
            tokenType: pbi.models.TokenType.Aad,
            accessToken: this.config.token,
            embedUrl: urlEmbed,
            id: this.config.reports[typeReport].id,
            permissions,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true,
              layoutType: pbi.models.LayoutType.MobilePortrait,
            },
          }

          const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)
          const reportContainer = document.getElementById('container')
          const report = powerbi.embed(reportContainer, config)

          report.iframe.style.border = 'none'
          report.off('loaded')
          report.off('rendered')
          report.on('error')

          this.heightIframeReportHandler()
        }
        this.$root.$emit('app::loading', false)
      })
        .catch(async error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(error)
        //   await this.redirectPageBack()
        })
    } catch (err) {
      //
    }
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async redirectPageBack() {
      let urlPrevRoute = this.prevRoute.path
      if (urlPrevRoute === '/') {
        urlPrevRoute = '/dashboard'
      }

      this.$router.push({ path: urlPrevRoute }).then(() => {
        this.$root.$emit('app::loading', false)
      })
    },
  },
  setup() {
    const POWERBI_MODULE_NAME = 'powerbi'

    if (!store.hasModule(POWERBI_MODULE_NAME)) {
      store.registerModule(POWERBI_MODULE_NAME, powerbiModule)

      onUnmounted(() => {
        if (store.hasModule(POWERBI_MODULE_NAME)) store.unregisterModule(POWERBI_MODULE_NAME)
      })
    }

    const { route } = useRouter()
    const paramsUrl = route.value.params
    store.dispatch('powerbi/setParamsUrl', paramsUrl)

    const heightIframeReportHandler = () => {
      const { height } = useWindowSize()

      let hIframeReport = height.value

      if (document.querySelector('.navbar-container') !== null) {
        hIframeReport -= parseInt(document.querySelector('.navbar-container').offsetHeight, 10) || 0
      }

      if (document.querySelector('.title-report') !== null) {
        hIframeReport -= parseInt(document.querySelector('.title-report').offsetHeight, 10) || 0
      }

      hIframeReport -= parseInt(useCssVar('--safe-area-inset-top', document.documentElement).value, 0) || 0
      hIframeReport -= parseInt(useCssVar('--safe-area-inset-bottom', document.documentElement).value, 0) || 0

      const sContentPaddingTop = useCssVar('--content-padding-top', document.documentElement).value
      if (sContentPaddingTop) {
        hIframeReport -= parseFloat(sContentPaddingTop) * parseFloat(getComputedStyle(document.documentElement).fontSize)
      }

      hIframeReport -= 5

      document.getElementById('container').style.height = `${hIframeReport}px`
    }

    window.addEventListener('orientationchange', heightIframeReportHandler)
    onUnmounted(() => {
      window.removeEventListener('orientationchange', heightIframeReportHandler)
    })

    return {
      heightIframeReportHandler,
    }
  },
}
</script>
